<template>
  <v-dialog
    :value="value"
    max-width="1300"
    width="1300"
    persistent>
    <v-card tile flat>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-card-title>
            Related Items
          </v-card-title>
        </v-toolbar-title>
        <v-select
          v-model="groupId"
          label="Item Group"
          :items="groups"
          item-text="group_name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          style="max-width: 35%"
          class="ml-2"
          hide-details
          outlined
          dense>
        </v-select>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!readOnly"
          small
          :disabled="(localAddedItems.length === 0 && removedItems.length === 0) || adding"
          :loading="adding"
          color="primary"
          @click="add">
          {{ itemSearch ? 'Add to Search' : 'Add Items' }}
        </v-btn>
        <v-btn
          small
          text
          class="ml-4"
          @click="updateOnClose ? $emit('close', true) : $emit('cancel')">
          Close
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
       <v-container fluid class="ma-0 pa-0">
        <v-data-table
          :height="tableSize"
          :headers="headers"
          :items.sync="relatedItems"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [-1]}"
          :loading="loading"
          fixed-header
          calculate-widths
          sort-by="item_id"
          dense>
          <template v-slot:[`header.included`]>
            <v-simple-checkbox
              v-if="!readOnly"
              dark
              :disabled="(relatedItems.length === 0 || includeAllDisabled)"
              v-model="includeAll"
              dense
              :ripple="false">
            </v-simple-checkbox>
          </template>

          <template v-slot:[`item.included`]="{ item }">
            <template v-if="!isIncluded(item)">
              <v-simple-checkbox
                v-if="!readOnly"
                color="primary"
                v-model="item.selected"
                @input="itemSelected($event, item)"
                :disabled="!isAvailableItem(item)"
                dense
                :ripple="false">
              </v-simple-checkbox>
            </template>
            <v-icon v-else color="success">
              mdi-check
            </v-icon>
          </template>
        </v-data-table>
       </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
// API
import ItemGroups from '@/axios/item-endpoints'
// Mixins
import { displayAlert } from '@/mixins/alert'
import { format } from '@/mixins/format'
import { userAccess } from '@/mixins/user-access'
export default {
  data () {
    return {
      nonTableHeight: 350,
      tableSize: '',
      relatedItems: [],
      groups: [],
      groupId: null,
      localAddedItems: [],
      includeAll: false,
      adding: false,
      loading: false,
      headers: [
        { text: '', align: 'center', value: 'included', sortable: false, filterable: false, class: 'accent white--text' },
        // { text: 'Source', sortable: false, value: 'source', class: 'accent white--text' },
        { text: 'Item Id', sortable: false, value: 'item_id', class: 'accent white--text' },
        { text: 'Description', sortable: false, value: 'item.description', class: 'accent white--text' },
        { text: 'UPC', sortable: false, value: 'item.upc', class: 'accent white--text' },
        { text: 'Vendor Id', sortable: false, value: 'item.vendor_id', class: 'accent white--text' },
        { text: 'Vendor Name', sortable: false, value: 'item.vendor_name', class: 'accent white--text' },
        { text: 'Retail Price', sortable: false, value: 'item.sale_price', class: 'accent white--text' },
        { text: 'Cost', sortable: false, value: 'item.cost', class: 'accent white--text' },
        { text: 'Pack Size', sortable: false, value: 'item.pack_size', class: 'accent white--text' }
      ],
      updateOnClose: false
    }
  },
  name: 'RelatedItemsModal',
  mixins: [displayAlert, format, userAccess],
  props: {
    value: Boolean,
    item: Object,
    currentItems: Array,
    addedItems: Array,
    tableItems: Array,
    readOnly: {
      type: Boolean,
    },
    itemSearch: {
      type: Boolean
    }
  },
  watch: {
    groupId: {
      handler (newValue) {
        if (newValue != null) {
          this.getRelatedItems()
        }
      }
    },
    includeAll: {
      handler (newValue) {
        if (newValue === false && this.availableItems.some(item => !this.isAddedLocally(item))) {
          return
        }
        this.relatedItems = this.relatedItems.map(item => {
          if (this.isAvailableItem(item)) {
            item.selected = newValue
            this.itemSelected(newValue, item)
          }
          return item
        })
      }
    },
  },
  async created () {
    this.tableSize = window.innerHeight - this.nonTableHeight
    if (this.item) {
      await this.getItemGroups()
    }
  },
  computed: {
    availableItems () {
      return this.relatedItems.filter(item => this.isAvailableItem(item))
    },
    includeAllDisabled () {
      return (this.availableItems.length === 0)
    },
    removedItems () {
      return this.relatedItems.flatMap(item => {
        return item && (this.isAdded(item) && !this.isTableItem(item) && !item.selected)
          ? item.item
          : []
      })
    }
  },
  methods: {
    async getItemGroups () {
      const item = this.item
      this.loading = true
      let groups = []
      try {
        const res = await ItemGroups.getGroupsByItem(item.source, item.item_id)
        if (res?.data?.length > 0) {
          groups = res.data.filter(group => group?.group_type?.constant !== 'COMPONENT_ITEM')
        }
        this.groups = groups
        if (this.groups.length > 0) {
          if (!this.groupId) this.groupId = this.groups[0].id
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    async getRelatedItems () {
      if (!this.groupId) return
      this.loading = true
      let relatedItems = []
      try {
        for await (let results of ItemGroups.getGroupItems(this.groupId)) {
          if (results?.length > 0) {
            results = results.flatMap(groupItem => {
              if (this.isSameItem(this.item, groupItem.item)) {
                return []
              }
              return this.formatGroupItem(groupItem)
            })
            relatedItems.push(...results)
          }
        }
        this.relatedItems = relatedItems
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    formatGroupItem (groupItem) {
      groupItem.selected = this.isSelected(groupItem)
      groupItem.included = this.isIncluded(groupItem)
      if (groupItem?.item) {
        groupItem.item.cost = `$${this.formatCurrencyDisplay(groupItem.item.cost)}`
        groupItem.item.sale_price = `$${this.formatCurrencyDisplay(groupItem.item.sale_price)}`
      }
      return groupItem
    },
    isSelected (item) {
      return this.isAdded(item) || this.isAddedLocally(item)
    },
    isSameItem (item1, item2) {
      return (item1?.item_id === item2.item_id) && (item1?.source === item2.source)
    },
    isIncluded (item) {
      return this.currentItems.some(i => {
        return this.isSameItem(i, item)
      })
    },
    itemSelected (value, item) {
      if (value === true) {
        if (!this.isAddedLocally(item)) {
          this.localAddedItems.push(item)
        }
      } else {
        const index = this.localAddedItems.findIndex(p => {
          return (p?.item_id === item.item_id) && (p?.source === item.source)
        })
        if (index > -1) this.localAddedItems.splice(index, 1)
        if (this.includeAll) {
          this.includeAll = false
        }
      }
    },
    isAddedLocally (item) {
      return Boolean(this.localAddedItems.find(i => this.isSameItem(i, item)))
    },
    isAdded (item) {
      return Boolean(this.addedItems?.find(i => this.isSameItem(i, item)))
    },
    isAvailableItem (item) {
      return !this.isIncluded(item) && !this.isTableItem(item)
    },
    isTableItem (item) {
      return Boolean(this.tableItems?.find(i => this.isSameItem(i, item)))
    },
    add () {
      const addedItems = this.localAddedItems.flatMap(groupItem => {
        if (this.isAvailableItem(groupItem)) {
            return groupItem.item
          }
        return []
      })
      this.$emit('search', addedItems)
    }
  }
}
</script>